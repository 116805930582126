.firebaseui-idp-button{
    border-radius:0.25rem !important;
    max-width:calc(100vw - 2rem) !important;
    display:flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.firebaseui-idp-list{
    margin:0 !important;
}
.firebaseui-card-content{
    padding:0 1rem !important;
}
.firebaseui-idp-text-short{
    display:none !important;
}
.firebaseui-idp-password{
    background: #9BD7BD !important; 
}