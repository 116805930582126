body {
  background: #FBFBFB;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.p-buttonCustom {
  background-color: #AA485F !important;
}
.p-checkbox .p-checkbox-box.p-highlight{
  background-color: #AA485F !important;
  border-color: #AA485F !important;
}

.p-buttonCustomLightBg {
  background-color: rgba(168, 71, 94, 0.1) !important;
  color: black !important;
}

.p-buttonBorder0 {
  border-radius: 0 !important;
  border: none !important;
}
.p-buttonBorderTop0{
  border-radius:inherit !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: none !important;
}

.p-buttonCustomTextColor {
  color: #AA485F !important;
}

.p-fullWidthCustom {
  width: 100% !important;
}

.alignItemToCenter {
  margin: auto !important;
  display: block !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #59AE89 !important;
}

.customPaddingForButton {
  padding: 0 !important;
}

/* .p-dialog-content {
  padding-top: 0.25rem !important;
  border-bottom: 6px solid #59AE89;
} */

.defaultSidebar .p-sidebar-header,
.defaultSidebar .p-sidebar-content {
  padding: 0 !important;
}

.defaultSidebar {
  width: 100vw !important;
  background: #F1F5F9 !important;
}

/* //global prime react styling */
.p-inputtext:enabled:focus {
  border-color: #AA485F !important;
}

.p-chip.custom-chip {
  background: #EDE6D0;
}

.p-tabview-nav-link {
  text-align: center !important;
}

.p-tabview-title {
  width: 100% !important;
}

.p-unselectable-text {
  padding: 0 !important;
}

.p-tabview-nav {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
}

.p-tabview-selected>.p-tabview-nav-link {
  color: #59AE89 !important;
}

.p-tabview-ink-bar {
  background: #59AE89 !important;
}

#yourCustomersSalesperson .p-inputtext {
  border-radius: 0 !important;
}

#yourCustomersSalesperson .p-button-label {
  font-weight: 400;
}

/* p-tabview-selected p-highlight */
.p-calendar .p-inputtext {
  padding: 0 !important;
  width: 100px !important;
  text-align: right !important;
  border: none !important;
}
.p-calendar .p-inputtext:focus{
  box-shadow: none !important;
  cursor:none !important;
}
.p-datepicker-monthpicker{
  width:80vw !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight{
  background:#AA485F !important;
  border-color: #AA485F !important;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #AA485F !important;
}

.p-sidebar-header{
  display:flex !important;
  justify-content: space-between !important;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

}

.p-bottom-padding{
  padding-bottom: 6px;
}

.share-button-green{
  background: #fbfbfb !important;
  color: #2CAC4C !important;
  border: 0px !important;
}

.text-overflow{
  display: block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.track-right{
  position: absolute;
  right: 2.5rem
}

.p-track-button{
  background-color: #ffffff !important;
  border-color: #AA485F !important;
  color: #AA485F !important;
}

@media (max-width: 640px) {
  .mobile-line-height {
    line-height: 16px;
    font-size: 14px;
    
  }
  .font-size{
    font-size: 14px;
  }
}

.card{
  display: grid;
  grid-template-columns: 20% 80%;
  min-width: 100%;

}
@media (max-width: 767px) {
  .mobileCard {
    display:grid;
    grid-template-columns: 20% 80%;
    min-width: 100%;
  }
}
.priceCard {
  display: grid;
  grid-template-columns: 50% 1px 50%; 
  min-width: 100%;
}

.dotted-line {
  border-bottom: 1px dashed #C9C9C9; 
  height: 100%; 
  margin: 0 8px;
}


.imgCard{
  background-color: white;
  border-right: 1px solid #E6E6E6;
}
.index-row{
  border-bottom: 1px dashed;
  height: 1.2rem;
  clear: both;
}
.index-row span{
  float: right;
}

.offer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.offer-content {
  flex: 0 0 30%;
  box-sizing: border-box;
  margin: 10px;
}


@media (max-width: 768px) {
  .offer-container {
    display: block;
  }

  .offer-content {
    flex: none;
    width: 100%;
    margin: 10px 0;
  }
}

.offer-ticket {

  background-color: #ffffff;
  border-radius: 1px;
  padding: 15px 40px 15px 40px; 
  position: relative;

}

.offer-ticket::before,
.offer-ticket::after {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  background: white;
  border: none;
  border-radius: 50%;
  top: 35%;
  transform: translateY(-50%);

}

.offer-ticket::before {
  left: -20px;
  border-right: 3px solid #e6e6e6;
 
}

.offer-ticket::after {
  right: -20px;
  border-left: 3px solid #e6e6e6;
  
}



.offer-code {
  border: 1px dashed #59AE89;
  background-color: #59ae8921;
  padding: 2px;
  border-radius: 4px;
  display: inline-block;
  width: auto;
}

.code-text {
  color:  #59AE89;
  font-weight: 500;
  font-size: 14px;
}

.offer-name {
  font-weight: 700;
  color: #000;
  font-size: 16px;
  
  margin: 1px 10px 0 0;
}
.offer-validity{
  font-weight: 500;
  color: #000;
  font-size: 16px;
}

.dashed-line {
  border-top: 1.5px dashed #EDE6D0;
  margin: 7px 0;
}

.offer-description {
  color: #333333;
}

.offer-discount {
  color: #ff4500;
  font-weight: bold;
}




