@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import url("./assets/css/index.css");

@layer components {
    .card-primary {
        @apply p-4 border border-grayE6E6E6 bg-white100 shadow rounded;
    }

    .flex-justify-between {
        @apply flex justify-between items-center;
    }

    .fix-bottom {
        @apply fixed bottom-0 right-0 left-0 p-4 bg-white100;
    }

    .border-bottom {
        @apply border-b border-grayE6E6E6 pb-2 mb-2
    }

    .input-fit{
        @apply text-center leading-3 p-1 bg-yellowEDE6D0 rounded block w-8
    }
}